import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bottomMenu } from "../constant/variable";
import { useNavbarContext } from "../context/navbar-context";

const Navbar = () => {
  const [state, reducer] = useNavbarContext();
  const [keyword, setKeyword] = useState("");
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (state.keyword.toLowerCase() === keyword.toLowerCase()) {
        return;
      }
      e.preventDefault();
      e.target.blur();
      reducer({ type: "isloading" });
      reducer({ type: "keyword", payload: keyword });

      navigate("/search?q=" + encodeURIComponent(keyword), {
        state: {
          keyword,
        },
      });
      reducer({ type: "closeMenu" });
    }
  };

  const handleCLick = (e) => {
    if (state.keyword.toLowerCase() === keyword.toLowerCase()) {
      return;
    }
    reducer({ type: "isloading" });
    reducer({ type: "keyword", payload: keyword });
    e.preventDefault();
    e.target.blur();
    navigate("/search?q=" + encodeURIComponent(keyword), {
      state: {
        keyword,
      },
    });
    reducer({ type: "closeMenu" });
  };
  return (
    <>
      <header
        className={
          (state.scrolled
            ? " h-16  shadow-md z-50   fixed left-0 right-0  bg-white "
            : " h-20  ") +
          "flex gap-x-2 justify-between items-center py-6 px-6 md:px-12 transition-all duration-200 z-50  text-gray-900 "
        }
      >
        <Link
          to="/"
          className={
            (state.loading && " pointer-events-none ") +
            " flex justify-center items-center space-x-2"
          }
          aria-label="brand"
        >
          <img src="/logo192.png" height={50} width={50} alt="Logo" />
          <h1 className="font-bold text-xl text-neutral hidden md:block">
            Resepku
          </h1>
        </Link>
        <div className={" flex content-center mx-auto md:ml-auto md:mr-0"}>
          <input
            disabled={state.loading}
            value={keyword ?? ""}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={(e) => handleKeyDown(e)}
            type="text"
            placeholder="Resepku"
            className={
              (state.loading ? " disabled " : " ") +
              " placeholder:text-orange-600 block rounded-r-none py-2 px-4 w-full border border-r-0 rounded-3xl  focus:outline-none"
            }
          />
          {state.loading ? (
            <button
              className={
                " group hover:bg-orange-600 px-4  border-l rounded-r-3xl border "
              }
              aria-label="Tombol Cari"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 group-hover:text-white text-orange-600"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          ) : (
            <button
              onClick={(e) => !state.loading && handleCLick(e)}
              className={
                " group hover:bg-orange-600 px-4  border-l rounded-r-3xl border "
              }
              aria-label="Tombol Cari"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          )}
        </div>
        <nav className="lg:flex lg:flex-row">
          <button
            aria-label="Menu Toggler"
            className="md:hidden p-2"
            onClick={() => reducer({ type: "toggleMenu" })}
          >
            <div className="">
              <span
                className={
                  "bg-orange-600 w-7 h-[0.15rem] block transform transition duration-500 ease-in-out" +
                  (state.isOpen ? " rotate-45 translate-y-1.5" : "")
                }
              ></span>
              <span
                className={
                  "bg-orange-600 w-5 h-[0.15rem] block my-1 transform transition duration-500 ease-in-out" +
                  (state.isOpen && " opacity-0")
                }
              ></span>
              <span
                className={
                  "bg-orange-600 w-7 h-[0.15rem] block transform transition duration-500 ease-in-out" +
                  (state.isOpen ? " -rotate-45 -translate-y-1.5" : "")
                }
              ></span>
            </div>
          </button>

          <ul
            id="menu"
            onClick={() => reducer({ type: "closeMenu" })}
            className={
              (state.isOpen ? " translate-x-0 " : "  -translate-x-full ") +
              (state.scrolled ? " top-16 pt-5 " : " top-20 ") +
              " min-h-screen  px-5  fixed left-0 right-0  md:top-0 transform transition duration-500  md:relative md:flex md:min-h-0 md:space-y-0 md:space-x-6 md:p-0 text-lg  z-50 md:translate-x-0 bg-white lg:bg-inherit "
            }
          >
            {bottomMenu
              .filter((menu) => menu.id !== "search")
              .map((menu) => {
                return (
                  <li key={menu.id} className="items-center flex gap-2">
                    <Link
                    onClick={()=>reducer({type:"moremenu",payload:true})}
                      className={
                        (state.loading && " pointer-events-none ") +
                        ((pathname === menu.path ||
                          "/" + pathname.split("/")[1] === menu.path ||
                          (pathname.split("/")[1] === "category" &&
                            menu.path === "/recipe")) &&
                          " bg-slate-100 rounded-lg outline-1 ") +
                        " md:py-4 py-1.5 px-2 flex gap-1 items-center "
                      }
                      to={menu.path}
                    >
                      <svg className="h-5 w-5" viewBox="0 0 24 24">
                        <path fill="currentColor" d={menu.icon} />
                      </svg>
                      {menu.ariaLabel}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
