import React, { Suspense, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import Hero from "../components/Hero";

import Navbar from "../components/Navbar";
import { articleCategory, baseUrl } from "../constant/variable";
import { useNavbarContext } from "../context/navbar-context";
import { CardLoader } from "../helper/Loader";

const CardGrid = React.lazy(() => import("../components/CardGrid"));
const ArticleList = ({ type }) => {
  const { slug } = useParams();
  const [state, reducer] = useNavbarContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const scrollFun = () => {
      if (window.pageYOffset > 40) {
        reducer({ type: "scroll", payload: true });
      } else {
        reducer({ type: "scroll", payload: false });
      }
    };

    const body = document.querySelector("body");
    if (state.isOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [reducer, state, slug]);
  return (
    <>
      <Navbar></Navbar>
      <div className="px-4 md:px-14 lg:px-18  pt-2 mb-3">
        <h1 className="text-center mb-3">Kategori Artikel</h1>
        <div className="flex gap-x-4 content-center justify-center">
          {articleCategory.map((article) => {
            return (
              <Link
                to={"/article/" + article.path}
                key={article.id}
                className={state.loading ? " pointer-events-none " : " "}
              >
                <div className="flex gap-2 items-center p-2 relative border rounded-lg ">
                  <svg
                    className="h-6 w-6 absolute -top-3 -right-3 rounded-full border p-0.5 bg-slate-50 text-orange-600"
                    viewBox="0 0 24 24"
                  >
                    <path fill="currentColor" d={article.icon} />
                  </svg>
                  <span className="break-words text-center">
                    {article.category}
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      
      <div className="px-4 md:px-14 lg:px-18">
        <Suspense fallback={<CardLoader />}>
          <CardGrid
            url={
              type === "home"
                ? baseUrl + "article"
                : baseUrl + "article/" + slug
            }
            type="article"
          />
        </Suspense>
      </div>
    </>
  );
};

export default ArticleList;
