import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import Card from "../components/Card";
import Navbar from "../components/Navbar";
import { useNavbarContext } from "../context/navbar-context";

const Favorite = () => {
  const [state, dispatch] = useNavbarContext();
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    const scrollFun = () => {
      if (window.pageYOffset > 40) {
        dispatch({ type: "scroll", payload: true });
      } else {
        dispatch({ type: "scroll", payload: false });
      }
    };

    const body = document.querySelector("body");
    if (state.isOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [dispatch, state]);

  useEffect(() => {
    document.title = "Resep Favorit Anda";
    const value = Object.entries(localStorage);
    const data = value
      .filter((x) => x[0].startsWith("resepku-"))
      .map((x) => {
        return {
          slug: x[0].slice(8),
          recipe: JSON.parse(x[1]),
        };
      });
    setRecipes(data);
  }, []);

  return (
    <>
      <Navbar />
      <div className="px-4 md:px-14 lg:px-18]">
        <Breadcrumb
          breadcrumb={[
            { slug: "/", text: "Home" },
            { slug: "/favorite", text: "Favorite" },
          ]}
        />
        {recipes.length < 1 ? (
          <div className="flex flex-col  items-center justify-center min-h-[50vh] text-center">
            <h1 className="text-2xl ">
              Opps !! Anda belum punya Resesp Favorit
            </h1>
            <h2 className="text-xl my-4">Yuk mulai cari resep kesukaan Anda</h2>
            <Link
              to={"/recipe"}
              className="btn btn-outline btn-ghost hover:bg-orange-600 hover:border-orange-600"
            >
              Explore Resep
            </Link>
          </div>
        ) : (
          <>
            <h1 className="text-2xl mt-4 my-6">Resep favorite Anda</h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 my-5">
              {recipes.map((recipe, i) => {
                return <Card data={recipe.recipe} key={i} type={"recipe"} />;
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Favorite;
