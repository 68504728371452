import { createContext, useContext, useReducer } from "react";

const NavbarContext = createContext({});
export const useNavbarContext = () => useContext(NavbarContext);
const initialState = {
  isOpen: false,
  scrooled: false,
  loading: false,
  keyword: "",
  online: true,
  moreMenu:true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "toggleMenu":
      return { ...state, isOpen: state.isOpen ? false : true };
    case "closeMenu":
      return { ...state, isOpen: false };
    case "scroll":
      return { ...state, scrolled: action.payload };
    case "isloading":
      return { ...state, loading: true };
    case "loaded":
      return { ...state, loading: false };
    case "keyword":
      return { ...state, keyword: action.payload };
    case "online":
      return { ...state, online: action.payload };
    case "moremenu":
      return {...state, moreMenu:action.payload};
    default:
      return;
  }
};

export const NavbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navbarContextValue = [state, dispatch];
  return (
    <NavbarContext.Provider value={navbarContextValue}>
      {children}
    </NavbarContext.Provider>
  );
};
