import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { useEffect } from "react";
export default function Notfound() {
  
  useEffect(() => {
    document.title = "Halaman tidak ditemukan";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Navbar />
    
      <div className="hero min-h-[calc(100vh-18rem)] md:min-h-[calc(100vh-13rem)] bg-base-200">
        <div className="text-center hero-content">
          <div className="">
            <h1 className="mb-5 text-4xl font-bold">Halaman Tidak ditemukan</h1>
            <p className="mb-5">Halaman yang anda Cari tidak ada.</p>
            <Link className="btn btn-md" to="/">
              Kembali ke Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
