export const CardLoader = () => {
  return (
    <>
      <div className="card rounded-none w-96 md:w-80 lg:w-96">
        <div className="h-[10rem] bg-orange-100 animate-pulse aspect-[3/2]"></div>
        <div className=" p-0 m-0 py-4">
          <div className="flex space-x-2 mb-2">
            <span className="w-9 h-4 bg-orange-100 animate-pulse"></span>
            <span className="w-12 h-4 bg-orange-100 animate-pulse"></span>
            <span className="w-20 h-4 bg-orange-100 animate-pulse"></span>
          </div>
          <div className="w-full bg-orange-100 animate-pulse mb-2 h-4"></div>
          <div className="w-2/3 bg-orange-100 animate-pulse mb-2 h-4"></div>
        </div>
      </div>
    </>
  );
};

export const DetailLoader = () => {
  return (
    <div className=" ">
      <div className="w-full  my-4 flex gap-x-4">
        <div className="bg-orange-100 animate-pulse w-1/12 h-6"></div>
        <div className="bg-orange-100 animate-pulse w-2/12 h-6"></div>
        <div className="bg-orange-100 animate-pulse w-4/12 h-6"></div>
      </div>
      <div className="flex justify-between">
        <div className=" w-full md:w-[60%] ">
          <div className="w-full h-6 mt-4 bg-orange-100 animate-pulse "></div>
          <div className="w-1/3 h-6 my-1 bg-orange-100 animate-pulse "></div>
          <div className="w-full aspect-video my-3 bg-orange-100 animate-pulse rounded-xl"></div>
          <div className="w-full h-6 my-4 bg-orange-100 animate-pulse "></div>
          <div className="w-2/6 h-6 my-4 bg-orange-100 animate-pulse "></div>
          <div className="w-full h-4 mt-8 bg-orange-100 animate-pulse "></div>
          <div className="w-full h-4 mt-1 bg-orange-100 animate-pulse "></div>
          <div className="w-1/6 h-4 mt-1 bg-orange-100 animate-pulse "></div>
          <div className="w-full h-4 mt-1 bg-orange-100 animate-pulse "></div>
          <div className="w-full h-4 mt-1 bg-orange-100 animate-pulse "></div>
          <div className="w-full h-4 mt-1 bg-orange-100 animate-pulse "></div>
          <div className="w-3/6 h-4 mt-1 bg-orange-100 animate-pulse "></div>
          <div className="w-full h-4 mt-1 bg-orange-100 animate-pulse "></div>
        </div>
        <div className="hidden md:flex md:flex-col w-[37%] h-[60vh]">
          <div className="bg-orange-100 animate-pulse my-5 w-8/12 h-6"></div>
          <div className="flex flex-col gap-5">
            <div className="w-full flex gap-3 items-center border border-orange-100 rounded-2xl">
              <div className="w-32 h-24 bg-orange-100 animate-pulse rounded-l-2xl "></div>
              <div className="w-10/12">
                <div className="w-5/6 h-5 bg-orange-100 animate-pulse "></div>
                <div className="w-4/12 h-4 bg-orange-100 animate-pulse my-2"></div>
                <div className="flex gap-x-3 ">
                  <div className="bg-orange-100 animate-pulse h-2 w-1/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-3/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-2/12"></div>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-3 items-center border border-orange-100 rounded-2xl">
              <div className="w-32 h-24 bg-orange-100 animate-pulse rounded-l-2xl "></div>
              <div className="w-10/12">
                <div className="w-5/6 h-5 bg-orange-100 animate-pulse "></div>
                <div className="w-4/12 h-4 bg-orange-100 animate-pulse my-2"></div>
                <div className="flex gap-x-3 ">
                  <div className="bg-orange-100 animate-pulse h-2 w-1/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-3/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-2/12"></div>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-3 items-center border border-orange-100 rounded-2xl">
              <div className="w-32 h-24 bg-orange-100 animate-pulse rounded-l-2xl "></div>
              <div className="w-10/12">
                <div className="w-5/6 h-5 bg-orange-100 animate-pulse "></div>
                <div className="w-4/12 h-4 bg-orange-100 animate-pulse my-2"></div>
                <div className="flex gap-x-3 ">
                  <div className="bg-orange-100 animate-pulse h-2 w-1/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-3/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-2/12"></div>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-3 items-center border border-orange-100 rounded-2xl">
              <div className="w-32 h-24 bg-orange-100 animate-pulse rounded-l-2xl "></div>
              <div className="w-10/12">
                <div className="w-5/6 h-5 bg-orange-100 animate-pulse "></div>
                <div className="w-4/12 h-4 bg-orange-100 animate-pulse my-2"></div>
                <div className="flex gap-x-3 ">
                  <div className="bg-orange-100 animate-pulse h-2 w-1/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-3/12"></div>
                  <div className="bg-orange-100 animate-pulse h-2 w-2/12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GrowLoader = () => {
  return (
    <div className="flex items-center justify-center py-5 mb-5 ">
      <div className="dot-typing "></div>
    </div>
  );
};
