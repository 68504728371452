import { Routes, Route } from "react-router-dom";
import { NavbarProvider } from "./context/navbar-context";
import Home from "./Page/Home";
import Footer from "./components/Footer";
import RecipeCategory from "./Page/RecipeCategory";
import RecipeList from "./Page/RecipeList";
import Search from "./Page/Search";
import RecipeDetail from "./Page/RecipeDetail";
import ArticleDetail from "./Page/ArticleDetail";
import ArticleList from "./Page/ArticleList";
import Notfound from "./Page/Notfound";
import Favorite from "./Page/Favorite";

const App = () => {
  return (
    <>
      <NavbarProvider>
        <Routes>
          <Route path={"/"} element={<Home />}></Route>
          <Route path={"/recipe/"} element={<RecipeList />}></Route>
          <Route path={"/recipe/:slug"} element={<RecipeDetail />}></Route>
          <Route path={"/search"} element={<Search />}></Route>
          <Route path={"/category/:slug"} element={<RecipeCategory />}></Route>
          <Route
            path={"/article"}
            element={<ArticleList type={"home"} />}
          ></Route>
          <Route
            path={"/article/:slug"}
            element={<ArticleList type={"category"} />}
          ></Route>
          <Route
            path={"/article/:category/:slug"}
            element={<ArticleDetail />}
          ></Route>
          <Route path={"/favorite"} element={<Favorite />}></Route>
          <Route path={"/notfound"} element={<Notfound />}></Route>
          <Route path={"/*"} element={<Notfound />}></Route>
        </Routes>
        <Footer></Footer>
      </NavbarProvider>
    </>
  );
};

export default App;
