import React, { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import CategoryPill from "../components/CategoryPill";

import Navbar from "../components/Navbar";
import { baseUrl } from "../constant/variable";
import { useNavbarContext } from "../context/navbar-context";
import { CardLoader } from "../helper/Loader";

const CardGrid = React.lazy(() => import("../components/CardGrid"));
const RecipeList = () => {
  const { slug } = useParams();
  const [state, reducer] = useNavbarContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const scrollFun = () => {
      if (window.pageYOffset > 40) {
        reducer({ type: "scroll", payload: true });
      } else {
        reducer({ type: "scroll", payload: false });
      }
    };

    const body = document.querySelector("body");
    if (state.isOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [reducer, state, slug]);
  return (
    <>
      <Navbar></Navbar>
      <CategoryPill />
      <div className="px-4 md:px-14 lg:px-18">
        <Suspense fallback={<CardLoader />}>
          <CardGrid url={baseUrl + "recipe"} type="recipe" />
        </Suspense>
      </div>
    </>
  );
};

export default RecipeList;
