import { Link } from "react-router-dom";

const Card = ({ data, type }) => {
  return (
    <Link
      to={
        (type === "recipe" ? "/recipe/" : "/article/" + data.path + "/") +
        data.slug
      }
    >
      <div className="card rounded-none my-3 md:my-0 ">
        <div className="mb-2 flex items-center gap-x-4">
          {type.toLowerCase() === "recipe" ? (
            <>
              <span className="p-2  inline-block rounded-full border border-orange-200">
                <svg className="h-5 w-5 text-orange-400" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M18.06 23H19.72C20.56 23 21.25 22.35 21.35 21.53L23 5.05H18V1H16.03V5.05H11.06L11.36 7.39C13.07 7.86 14.67 8.71 15.63 9.65C17.07 11.07 18.06 12.54 18.06 14.94V23M1 22V21H16.03V22C16.03 22.54 15.58 23 15 23H2C1.45 23 1 22.54 1 22M16.03 15C16.03 7 1 7 1 15H16.03M1 17H16V19H1V17Z"
                  />
                </svg>
              </span>
              <div>
                <p>Resep Masakan</p>
                <p className="text-xs">{data?.difficulty}</p>
              </div>
            </>
          ) : (
            <>
              <span className="p-2  inline-block rounded-full border border-orange-200">
                <svg className="h-5 w-5 text-orange-400" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H11V19.13L19.39 10.74C19.83 10.3 20.39 10.06 21 10M14 4.5L19.5 10H14V4.5M22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19M19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83Z"
                  />
                </svg>
              </span>
              <div>
                <p>Artikel MAHI</p>
                <p className="text-xs">{data?.category}</p>
              </div>
            </>
          )}
        </div>
        <figure className="relative">
          <img
            src={data.image ? data.image : "/images/bg.jpg"}
            alt={data.title}
            loading="lazy"
            className=" aspect-[3/2] object-cover "
          />
          {data.isHaveVideo && (
            <span className="relative bottom-[1.85rem]  bg-orange-600 text-slate-100 text-xs p-2 opacity-80">
              Video Tersedia
            </span>
          )}
        </figure>

        <div className=" p-0 m-0 py-4">
          {type === "recipe" && (
            <div className="flex space-x-1 mb-3 text-sm">
              <span className="py-0.5 px-2 text-sm rounded-full border border-orange-500 hover:bg-orange-500 hover:text-white">
                ⏱ {data.duration}
              </span>
              <span className="py-0.5 px-2 rounded-full border border-orange-500 hover:bg-orange-500 hover:text-white">
                🍽 {data.serving}
              </span>

              {data.isHaveVideo && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-orange-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
          )}
          <h2 className="font-lg font-semibold mb-3">{data.title}</h2>
        </div>
      </div>
    </Link>
  );
};

export default Card;
