import { useEffect, useState } from "react";
import { baseUrl } from "../constant/variable";
import { CardLoader, GrowLoader } from "../helper/Loader";

import { Link, useNavigate,useLocation } from "react-router-dom";
import Card from "../components/Card";
import Navbar from "../components/Navbar";
import { useNavbarContext } from "../context/navbar-context";

const Search = () => {
  const pathName = useLocation();
  

  const params = new URLSearchParams(window.location.search);
 
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const query = params.get("q");

  const [state, reducer] = useNavbarContext();
  const [recipes, setRecipes] = useState([]);
  const [slices, setSlices] = useState([]);
  const [message, setMessage] = useState("");
  const [nextLoad, setNextLoad] = useState(false);

  const [found, setFound] = useState(true);
  const [page, setPage] = useState([]);

  const loadMore = async (i) => {
    setNextLoad(true);
    const newRecipe = recipes.slice(i * 12 - 12, 12 * i);
    setSlices(newRecipe);
    setNextLoad(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const scrollFun = () => {
      if (window.pageYOffset > 40) {
        reducer({ type: "scroll", payload: true });
      } else {
        reducer({ type: "scroll", payload: false });
      }
    };

    const body = document.querySelector("body");
    if (state.isOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [reducer, state]);

  useEffect(() => {
    async function getRecipes() {
      setActive(0);
      reducer({ type: "isloading" });
      const perPage = 12;
      const req = await fetch(baseUrl + "search?q=" + query, { method: "GET" });
      if (!req.ok) {
        return navigate("/notfound", {});
      }
      const response = await req.json();
      if (response.data.length <= 0) {
        reducer({ type: "loaded" });
        return setFound(false);
      }
      const data = response.data.recipes;

      setMessage(response.message);
      setRecipes(data);
      setSlices(data.slice(0, perPage));
      setPage([...Array(Math.ceil(data.length / perPage)).keys()]);

      reducer({ type: "loaded" });
    }
    getRecipes();
  }, [navigate, query, reducer]);

  return (
    <>
      <Navbar />
      {state.loading ? (
        <>
          <div className="px-4 md:px-14 lg:px-18">
            <h1 className="capitalize py-5 text-lg font-bold">
              Memuat data ...
            </h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 min-h-[60vh]">
              <CardLoader />
              <CardLoader />
              <CardLoader />
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </div>
          </div>
        </>
      ) : !found ? (
        <div className="hero min-h-[calc(100vh-18rem)] md:min-h-[calc(100vh-12rem)] bg-base-200">
          <div className="text-center hero-content">
            <div className="">
              {pathName.pathname ==='/recipe'?( <>
                  <h1 className="mb-10 text-2xl font-bold">
                  Resep tidak ditemukan
                  </h1>

                  <Link className="p-3 rounded-lg border" to="/">
                    Ke Halaman Home
                  </Link>
                </>):(
                <>
                  <h1 className="mb-10 text-2xl font-bold">
                  Hasil Pecarian tidak ditemukan
                  </h1>

                  <Link className="p-3 rounded-lg border" to="/recipe">
                    Ke Halaman resep
                  </Link>
                </>
              )}
              
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="px-4 md:px-14 lg:px-18">
            <h1 className="capitalize py-5  font-semibold">
              {message ? message + " (" + recipes.length + " item)" : ""}
            </h1>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 mb-5">
              {slices.map((recipe, i) => {
                return <Card data={recipe} key={i} type={recipe.path} />;
              })}
            </div>
            {nextLoad ? (
              <GrowLoader />
            ) : (
              <div className="flex flex-col w-full mb-5">
                <div className="divider">
                  <div id="pagination" className="btn-group">
                    {page.map((e, i) => {
                      return (
                        <a
                          href="/"
                          onClick={(event) => {
                            event.preventDefault();
                            setActive(i);
                            loadMore(e + 1);
                          }}
                          key={i}
                          className={
                            (active === i ? " bg-orange-200 " : " bg-white  ") +
                            " border-orange-400 hover:border-orange-400  text-slate-900  btn btn-sm  hover:bg-orange-200 "
                          }
                        >
                          {e + 1}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Search;
