import React, { useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useNavbarContext } from "../context/navbar-context";
import { DetailLoader } from "../helper/Loader";
const DetailArticleComp = React.lazy(() =>
  import("../components/DetailArticleComp")
);

const ArticleDetail = () => {
  const [state, reducer] = useNavbarContext();
  const { slug, category } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const scrollFun = () => {
      if (window.pageYOffset > 40) {
        reducer({ type: "scroll", payload: true });
      } else {
        reducer({ type: "scroll", payload: false });
      }
    };

    const body = document.querySelector("body");
    if (state.isOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [reducer, state]);
  return (
    <>
      <Navbar></Navbar>
      <div className="px-4 md:px-14 lg:px-18">
        <Suspense fallback={<DetailLoader />}>
          <DetailArticleComp slug={slug} category={category} />
        </Suspense>
      </div>
    </>
  );
};
export default ArticleDetail;
