import { Link } from "react-router-dom";
const Breadcrumb = ({ breadcrumb }) => {
  return (
    <>
      <div className="text-md breadcrumbs border-b hidden md:block">
        <ul className="px-4 ">
          {breadcrumb.map((d, i) => {
            return (
              <li key={i}>
                <Link to={d.slug}>{d.text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="text-md breadcrumbs border-b  md:hidden">
        <ul className="px-4 ">
          {breadcrumb.map((d, i) => {
            return (
              <li key={i}>
                <Link to={d.slug}>{d.text.substring(0, 10)}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Breadcrumb;
