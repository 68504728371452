import Carousel from "react-multi-carousel";
import "../Carousell.css";
import { Link } from "react-router-dom";
import { categories } from "../constant/variable";
import { useNavbarContext } from "../context/navbar-context";

const CategoryPill = () => {
  const [state,reducer] = useNavbarContext();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 168 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className={
        (state.scrolled ? " my-4 mt-0 " : " my-4 ") +
        " text-neutral text-sm px-3 md:px-6 lg:px-10 xl:px-14"
      }
    >
      <Carousel
        responsive={responsive}
        keyBoardControl={true}
        itemClass=" px-1"
      >
        {categories.map((c, index) => {
          return (
            <Link
              
              key={index}
              to={`/category/${c.slug}`}
              className={
                (state.loading && " pointer-events-none ") +
                " px-4 py-2 text-lg flex  justify-between h-20 border border-orange-600 rounded-md bg-neutral-content hover:bg-orange-600 hover:text-neutral-content"
              }
              onClick={()=>reducer({type:"moremenu",payload:true})}
            >
              <span  className="pr-1">{c.category}</span>
              <span className="h-6 w-6 "> {c.icon}</span>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CategoryPill;
