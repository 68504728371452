import { Link, useLocation } from "react-router-dom";
import { bottomMenu } from "../constant/variable";
import { useNavbarContext } from "../context/navbar-context";

const BottomMenu = () => {
  const [state,reducer] = useNavbarContext();
  const active = useLocation();
  // console.log(active.pathname);

  const menus = bottomMenu;
  return (
    <>
      <nav className="sticky md:hidden bottom-0 left-0 right-0 w-full border-b-0">
        <ul className="menu bg-base-100 menu-horizontal w-full border-t border-b-0 flex justify-between px-2 ">
          {menus.map((menu, i) => {
            return (
              <li key={i} className="py-0 ">
                <Link
                  onClick={()=>reducer({type:"moremenu",payload:true})}
                  to={menu.path ?? ""}
                  style={{ padding: "0.5rem" }}
                  className={
                    (state.loading && " pointer-events-none ") +
                    " active:bg-white visited:bg-white text-xs "
                  }
                >
                  <div
                    className={
                      ((menu.path === active.pathname ||
                        (menu.path === "/recipe") &
                          (active.pathname.split("/")[1] === "category") ||
                        menu.path === active.pathname + "?q=resep" ||
                        menu.path === "/" + active.pathname.split("/")[1]) &&
                        "text-orange-600 ") + " flex flex-col items-center "
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={" h-6 w-6 "}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d={menu.icon}
                      />
                    </svg>
                    {menu.ariaLabel}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default BottomMenu;
