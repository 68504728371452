export const baseUrl = "https://sandbox.nurhadi.net/index.php/masakapa/";
// export const baseUrl = "http://localhost/masakapa/";

export const sourceUrl = "https://www.masakapahariini.com/";
export const recipeCount = 200;
export const categories = [
  {
    category: "Dessert",
    slug: "resep-dessert",
    icon: `🍮`,
  },
  {
    category: "Masakan Hari Raya",
    slug: "masakan-hari-raya",
    icon: `🍱`,
  },
  {
    category: "Masakan Tradisional",
    slug: "masakan-tradisional",
    icon: "🍢",
  },
  {
    category: "Menu Makan Malam",
    slug: "makan-malam",
    icon: "🍝",
  },
  {
    category: "Menu Makan Siang",
    slug: "makan-siang",
    icon: "🍛",
  },
  {
    category: "Resep Ayam",
    slug: "resep-ayam",
    icon: "🍗",
  },
  {
    category: "Resep Daging",
    slug: "resep-daging",
    icon: "🥩",
  },
  {
    category: "Resep Sayuran",
    slug: "resep-sayuran",
    icon: "🥕",
  },
  {
    category: "Resep Seafood",
    slug: "resep-seafood",
    icon: "🦀",
  },
];

export const filtersRecipe = [
  {
    label: "Masakan Hari Raya",
    filters: [
      {
        filterId: "36",
        filter: "Sahur",
      },
      {
        filterId: "37",
        filter: "Buka puasa",
      },
      {
        filterId: "38",
        filter: "Idul Fitri",
      },
      {
        filterId: "39",
        filter: "Idul Adha",
      },
      {
        filterId: "40",
        filter: "Natal",
      },
      {
        filterId: "41",
        filter: "Ulang Tahun",
      },
      {
        filterId: "42",
        filter: "Masakan Perkawinan",
      },
      {
        filterId: "44",
        filter: "Tahun Baru",
      },
      {
        filterId: "45",
        filter: "Valentine",
      },
      {
        filterId: "46",
        filter: "Arisan",
      },
      {
        filterId: "113",
        filter: "Imlek",
      },
    ],
  },
  {
    label: "Bahan Utama",
    filters: [
      {
        filterId: "47",
        filter: "Ayam",
      },
      {
        filterId: "48",
        filter: "Seafood",
      },
      {
        filterId: "49",
        filter: "Daging",
      },
      {
        filterId: "50",
        filter: "Sayuran",
      },
      {
        filterId: "51",
        filter: "Nasi",
      },
      {
        filterId: "52",
        filter: "Mie",
      },
      {
        filterId: "53",
        filter: "Telur",
      },
      {
        filterId: "54",
        filter: "Tahu",
      },
      {
        filterId: "55",
        filter: "Tempe",
      },
    ],
  },
  {
    label: "Cara Memasak",
    filters: [
      {
        filterId: "56",
        filter: "Goreng",
      },
      {
        filterId: "57",
        filter: "Rebus",
      },
      {
        filterId: "58",
        filter: "Kukus",
      },
      {
        filterId: "59",
        filter: "Bakar",
      },
      {
        filterId: "60",
        filter: "Panggang",
      },
      {
        filterId: "111",
        filter: "Tumis",
      },
    ],
  },
  {
    label: "Peralatan Masak",
    filters: [
      {
        filterId: "64",
        filter: "Kompor",
      },
      {
        filterId: "65",
        filter: "Microwave",
      },
      {
        filterId: "66",
        filter: "Rice Cooker",
      },
      {
        filterId: "67",
        filter: "Tidak Dimasak",
      },
    ],
  },
  {
    label: "Rasa",
    filters: [
      {
        filterId: "68",
        filter: "Manis",
      },
      {
        filterId: "69",
        filter: "Gurih",
      },
      {
        filterId: "70",
        filter: "Rasa Pedas",
      },
      {
        filterId: "71",
        filter: "Asam",
      },
    ],
  },
  {
    label: "Tingkat Kesulitan",
    filters: [
      {
        filterId: "72",
        filter: "Kurang dari 5 bahan",
      },
      {
        filterId: "73",
        filter: "Kurang dari 30 menit",
      },
      {
        filterId: "74",
        filter: "Praktis dan Mudah",
      },
      {
        filterId: "104",
        filter: "Level Chef Panji",
      },
    ],
  },
  {
    label: "Tipe Masakan",
    filters: [
      {
        filterId: "75",
        filter: "Appetizer",
      },
      {
        filterId: "76",
        filter: "Salad",
      },
      {
        filterId: "77",
        filter: "Masakan Utama",
      },
      {
        filterId: "78",
        filter: "Resep Sup",
      },
      {
        filterId: "120",
        filter: "Minuman",
      },
    ],
  },
  {
    label: "Asal Menu",
    filters: [
      {
        filterId: "79",
        filter: "Jawa Tengah",
      },
      {
        filterId: "80",
        filter: "Jawa Barat",
      },
      {
        filterId: "81",
        filter: "Jawa Timur",
      },
      {
        filterId: "82",
        filter: "Bali",
      },
      {
        filterId: "83",
        filter: "Sunda",
      },
      {
        filterId: "84",
        filter: "Kalimantan",
      },
      {
        filterId: "85",
        filter: "Sumatra",
      },
      {
        filterId: "86",
        filter: "Padang",
      },
      {
        filterId: "87",
        filter: "Manado",
      },
      {
        filterId: "88",
        filter: "Masakan Internasional",
      },
      {
        filterId: "112",
        filter: "Betawi",
      },
      {
        filterId: "114",
        filter: "Sulawesi",
      },
    ],
  },
  {
    label: "Tingkat Kepedasan",
    filters: [
      {
        filterId: "89",
        filter: "Tidak Pedas",
      },
      {
        filterId: "90",
        filter: "Sedikit Pedas",
      },
      {
        filterId: "91",
        filter: "Pedas",
      },
    ],
  },
  {
    label: "Diet",
    filters: [
      {
        filterId: "92",
        filter: "Vegetarian",
      },
      {
        filterId: "93",
        filter: "Sehat",
      },
      {
        filterId: "94",
        filter: "Organik",
      },
      {
        filterId: "95",
        filter: "Vegan",
      },
      {
        filterId: "96",
        filter: "Bebas Susu",
      },
      {
        filterId: "98",
        filter: "Rendah Karbohidrat",
      },
      {
        filterId: "110",
        filter: "Rendah Gula",
      },
    ],
  },
  {
    label: "Jenis Masakan",
    filters: [
      {
        filterId: "116",
        filter: "Nasi Goreng",
      },
    ],
  },
];

export const articleCategory = [
  {
    id: 1,
    category: "Inspirasi Dapur",
    slug: "inspirasi-dapur",
    icon: "M17 22A3 3 0 1 0 14.18 18H9.14L11.13 14.94A6.36 6.36 0 0 0 12.87 14.94L13.89 16.5C14.31 16 14.85 15.56 15.5 15.3L14.89 14.37A7 7 0 0 0 19 8H5A7 7 0 0 0 9.12 14.37L5.17 20.45A1 1 0 0 0 6.84 21.54L7.84 20H14.18A3 3 0 0 0 17 22M17 18A1 1 0 0 1 18 19C18 19.55 17.55 20 17 20S16 19.55 16 19A1 1 0 0 1 17 18M7.42 10H16.58A5 5 0 0 1 7.42 10M9.41 7H10.41C10.56 5.85 10.64 5.36 9.5 4.04C9.1 3.54 8.84 3.27 9.06 2H8.07A3.14 3.14 0 0 0 8.96 4.96C9.18 5.2 9.75 5.63 9.41 7M11.89 7H12.89C13.04 5.85 13.12 5.36 12 4.04C11.58 3.54 11.32 3.26 11.54 2H10.55A3.14 3.14 0 0 0 11.44 4.96C11.67 5.2 12.24 5.63 11.89 7M14.41 7H15.41C15.56 5.85 15.64 5.36 14.5 4.04C14.1 3.54 13.84 3.27 14.06 2H13.07A3.14 3.14 0 0 0 13.96 4.96C14.18 5.2 14.75 5.63 14.41 7Z",
    path: "inspirasi-dapur",
  },
  {
    id: 2,
    category: "Makanan & Gaya Hidup",
    slug: "makanan-gaya-hidup",
    icon: "M4,2H6V4C6,5.44 6.68,6.61 7.88,7.78C8.74,8.61 9.89,9.41 11.09,10.2L9.26,11.39C8.27,10.72 7.31,10 6.5,9.21C5.07,7.82 4,6.1 4,4V2M18,2H20V4C20,6.1 18.93,7.82 17.5,9.21C16.09,10.59 14.29,11.73 12.54,12.84C10.79,13.96 9.09,15.05 7.88,16.22C6.68,17.39 6,18.56 6,20V22H4V20C4,17.9 5.07,16.18 6.5,14.79C7.91,13.41 9.71,12.27 11.46,11.16C13.21,10.04 14.91,8.95 16.12,7.78C17.32,6.61 18,5.44 18,4V2M14.74,12.61C15.73,13.28 16.69,14 17.5,14.79C18.93,16.18 20,17.9 20,20V22H18V20C18,18.56 17.32,17.39 16.12,16.22C15.26,15.39 14.11,14.59 12.91,13.8L14.74,12.61M7,3H17V4L16.94,4.5H7.06L7,4V3M7.68,6H16.32C16.08,6.34 15.8,6.69 15.42,7.06L14.91,7.5H9.07L8.58,7.06C8.2,6.69 7.92,6.34 7.68,6M9.09,16.5H14.93L15.42,16.94C15.8,17.31 16.08,17.66 16.32,18H7.68C7.92,17.66 8.2,17.31 8.58,16.94L9.09,16.5M7.06,19.5H16.94L17,20V21H7V20L7.06,19.5Z",
    path: "makanan-gaya-hidup",
  },
  {
    id: 3,
    category: "Tips Masak",
    slug: "tips-masak",
    icon: "M22 3L10 4.41V6H22V7H10V12H22C22 13.81 21.43 15.46 20.32 16.95S17.77 19.53 16 20.25V22H8V20.25C6.24 19.53 4.79 18.43 3.68 16.95S2 13.81 2 12H5V4L22 2V3M6 4.88V6H7V4.78L6 4.88M6 7V12H7V7H6M9 12V7H8V12H9M9 6V4.55L8 4.64V6H9Z",
    path: "tips-masak",
  },
];

export const bottomMenu = [
  {
    icon: "M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z",
    ariaLabel: "Home",
    path: "/",
    id: "home",
  },
  {
    icon: "M5.44 7.96L5.96 7.43C6.54 6.85 7.5 6.85 8.1 7.47L8.12 7.5C8.61 8 9.26 8.24 9.97 8.31C10.94 8.4 11.88 8.92 12.5 9.86C13.18 10.94 13.17 12.38 12.46 13.45C11.26 15.3 8.75 15.5 7.28 14C6.72 13.45 6.4 12.74 6.32 12C6.24 11.27 5.95 10.58 5.44 10.07C4.86 9.5 4.86 8.54 5.44 7.96M9.64 16C8.47 16 7.38 15.55 6.57 14.72C5.87 14 5.43 13.1 5.32 12.12C5.29 11.82 5.2 11.43 4.96 11.07C4.36 11.9 4 12.9 4 14C4 15.64 4.8 17.09 6.03 18H19V17C19 13.4 16.61 10.35 13.34 9.35C14.23 10.75 14.21 12.62 13.3 14C12.5 15.25 11.12 16 9.64 16M14.78 7.56H16.05C16.92 7.56 17.68 8.17 17.68 9.26V10H18.93V9C18.93 7.5 17.6 6.36 16.05 6.36H14.78C13.95 6.36 13.24 5.54 13.24 4.7S13.95 3.24 14.78 3.24V2C13.24 2 12 3.24 12 4.78S13.24 7.56 14.78 7.56M4.5 7.55C4.56 7.45 4.64 7.35 4.73 7.25L5.25 6.73C5.34 6.64 5.44 6.57 5.54 6.5L4.13 5.07C4.27 4.8 4.22 4.45 4 4.22C3.7 3.93 3.22 3.93 2.93 4.22C2.79 4.36 2.72 4.53 2.71 4.71C2.53 4.72 2.36 4.79 2.22 4.93C1.93 5.22 1.93 5.7 2.22 6C2.45 6.22 2.79 6.27 3.07 6.13L4.5 7.55M18.39 4.39C18.9 3.88 19.22 3.19 19.22 2.42H17.97C17.97 3.25 17.27 3.95 16.44 3.95V5.19C18.3 5.19 19.76 6.71 19.76 8.57V11H21V8.57C21 6.72 19.94 5.13 18.39 4.39M5 21H19C20.11 21 21 20.11 21 19H3C3 20.11 3.9 21 5 21Z",
    ariaLabel: "Resep",
    path: "/recipe",
    id: "recipe",
  },
  {
    icon: "M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z",
    ariaLabel: "Search",
    path: "/search?q=resep",
    id: "search",
  },

  {
    icon: "M10 20H6V4H13V9H18V12.1L20 10.1V8L14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H10V20M20.2 13C20.3 13 20.5 13.1 20.6 13.2L21.9 14.5C22.1 14.7 22.1 15.1 21.9 15.3L20.9 16.3L18.8 14.2L19.8 13.2C19.9 13.1 20 13 20.2 13M20.2 16.9L14.1 23H12V20.9L18.1 14.8L20.2 16.9Z",
    ariaLabel: "Artikel",
    path: "/article",
    id: "article",
  },
  {
    icon: "M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z",
    ariaLabel: "Favorite",
    path: "/favorite",
    id: "favorite",
  },
];
