import React, { useEffect, Suspense, useState } from "react";

import CategoryPill from "../components/CategoryPill";

import Navbar from "../components/Navbar";
import { useNavbarContext } from "../context/navbar-context";
import { baseUrl } from "../constant/variable";
import { CardLoader } from "../helper/Loader";
import Card from "../components/Card";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

const Home = () => {
  const [state, reducer] = useNavbarContext();
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    document.title = "Resepku - Resep Kuliner Terlengkap";
    const scrollFun = () => {
      if (window.pageYOffset > 40) {
        reducer({ type: "scroll", payload: true });
      } else {
        reducer({ type: "scroll", payload: false });
      }
    };

    const body = document.querySelector("body");
    if (state.isOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [reducer, state]);

  useEffect(() => {
    reducer({ type: "isloading" });
    const getData = async () => {
      const req = await fetch(baseUrl + "home", { method: "GET" });
      if (!req.ok) {
        return setData([]);
      }
      const response = await req.json();
      setData(response.data.list);
      setBanner(response.data.banner);
      reducer({ type: "loaded" });
    };
    getData();
  }, [reducer]);

  const responsive = {
    mobile: {
      breakpoint: { min: 0, max: 5000 },
      items: 1,
    },
  };

  const BannerEl = ({ data }) => {
    const path = data.slug
      ? data.category === "recipe"
        ? `/recipe/${data.slug}`
        : `/article/${data.path}/${data.slug}`
      : "";
    return (
      <>
        <div className="rounded-lg">
          <img
            src={data.image ? data.image : "/images/bg.jpg"}
            alt="banner "
            className="h-[40vh] md:h-[60vh] w-full object-cover rounded-lg "
          />
        </div>
        <Link to={path}>
          <div className="absolute hero-content h-fit text-white bottom-10 md:bottom-20 px-8 py-[0.88rem] rounded-b-lg w-full flex flex-col items-center ">
            <div className="hero-overlay w-fit p-4 rounded-md flex  flex-col items-center">
              <h1 className="text-xl text-center md:font-semibold">
                {data.title ?? "Resep Kuliner terlengkap?"}
              </h1>

              <div className="mt-4">
                {data.category === "recipe" ? (
                  <div className="flex space-x-1 mb-3 text-sm">
                    <span className="py-0.5 px-2 text-sm rounded-full border border-orange-500 hover:bg-orange-500 hover:text-white">
                      ⏱ {data.duration}
                    </span>
                    <span className="py-0.5 px-2 rounded-full border border-orange-500 hover:bg-orange-500 hover:text-white">
                      🍽 {data.serving}
                    </span>
                    <span className="py-0.5 px-2 rounded-full border border-orange-500 hover:bg-orange-500 hover:text-white">
                      👩‍🍳 {data.difficulty}
                    </span>

                    {data.isHaveVideo && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-orange-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>
                ) : (
                  <span className="py-0.5 px-2 rounded-full border border-orange-500 hover:bg-orange-500 hover:text-white">
                    📝 Artikel Terbaru
                  </span>
                )}
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  };

  return (
    <>
      <Navbar></Navbar>
      <CategoryPill></CategoryPill>

      <div className="w-full  px-4 md:px-9 lg:px-18 my-8">
        <div className="lg:px-6">
          {state.loading ? (
            <div className="h-[40vh] md:h-[60vh] w-full bg-orange-100 animate-pulse rounded-lg"></div>
          ) : (
            <>
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                showDots={true}
              >
                {banner
                  .filter((ban) => ban.path !== null)
                  .map((b, i) => {
                    return <BannerEl key={i} data={b} />;
                  })}
              </Carousel>
            </>
          )}
        </div>
      </div>
      <div className="px-4 md:px-9 lg:px-18 my-5">
        {state.loading ? (
          <>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 mb-5 lg:px-6">
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </div>
          </>
        ) : (
          <Suspense fallback={<CardLoader />}>
            <div className="mt-10">
              <h2 className="text-center text-lg font-semibold mt-8 mb-6">
                Post Terbaru Kami
              </h2>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 mb-5 lg:px-6">
                {data.map((recipe, i) => {
                  return <Card data={recipe} key={i} type={recipe.path} />;
                })}
              </div>
            </div>
          </Suspense>
        )}
      </div>
    </>
  );
};

export default Home;
