import BottomMenu from "./BottomMenu";

const Footer = () => {
  return (
    <>
      <footer className="bg-gray-50 px-4 md:px-14 lg:px-18 py-4">
        <div className="flex flex-col md:flex-row gap-5 pb-4 ">
          <div className="">
            <a href="http://nurhadi.net">
              <h1 className="font-bold text-lg mb-4">
                Resepku{" "}
                <small className="tex-xs font-normal">by nurhadi.net</small>
              </h1>
            </a>
            <p className="text-sm text-justify">
              Aplikasi ini dibangun menggunakan framework{" "}
              <a
                href="https://lumen.laravel.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold"
              >
                Lumen
              </a>{" "}
              sebagai crawler dari website{" "}
              <a
                href="http://masakapahariini.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                www.masakapahariini.com
              </a>
              . Disisi front end, menggunakan{" "}
              <a
                href="https://reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold"
              >
                React JS
              </a>{" "}
              dan{" "}
              <a
                href="https://tailwindcss.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold"
              >
                Tailwind CSS
              </a>{" "}
              .
            </p>
            <p className="text-sm">
              Web App ini ditujukan sebagai portofolio, bukan untuk komersil.
              semua resep dan artkel bersumber dari{" "}
              <a
                href="http://masakapahariini.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                MAHI (Masak Apa Hari Ini)
              </a>
              .
            </p>
          </div>
        </div>
      </footer>
      <BottomMenu />
    </>
  );
};

export default Footer;
